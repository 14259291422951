import React from "react";
import styled from "styled-components";
import { globalColors, GlobalStyle } from "../assets/GlobalStyles";
import Nav from "../components/Nav";

const About = (props) => {
  return (
    <>
      <GlobalStyle />
      <Nav url={props.path} />
      <AboutWrapper>
        <h1>About</h1>
        <p>Hello my name is Trish! I’m based in Brooklyn, NY.<StyledMailLink href = "mailto: trishramirezphotography@gmail.com"> Shoot me a message</StyledMailLink>, I’d love to chat about your creative + photo needs! Open to booking portraits, engagement, wedding and styled shoots. </p>
      </AboutWrapper>
    </>
  );
};



export default About;

const AboutWrapper = styled.div`
  font-family: program-narrow,  sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 3em auto 0 auto;
  padding: 0 auto;
  width: 50%;
  height: 100%;
  line-height: 2em;
  h1 {
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 1.5rem;
    margin-top: 1em;
    padding: 0;
    text-align: left;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const StyledMailLink = styled.a`
  text-decoration: none;
  color: ${globalColors.brandWhite}
  `
